<script setup>
import logoSmall from "../../../assets/logo-small.png";
import logo from "../../../assets/logo.png";
import lock from "../../../assets/lock.svg"
import envelope from "../../../assets/envelope.svg"
import ForgotPassword from "../../Modules/Components/Modal/ForgotPassword.vue";
import ResetPassword from "../../Modules/Components/Modal/ResetPassword.vue";
import OtpModal from "../../Modules/Components/Modal/OtpModal.vue";
import ResetPwdSuccess from "../../Modules/Components/Modal/ResetPwdSuccess.vue";
import PasswordCriteriaTooltip from "../../Modules/Components/Common/PasswordCriteriaTooltip.vue";
import { AGENT } from "../../Modules/Constants/generic";
import {ref,computed, toRefs, watch ,reactive,onMounted} from 'vue'
import { useLogin } from "../../Modules/Stores/login";
import HttpStatusCodes from "../../../../Admin/js/Constants/http";
import forge from "node-forge";
import {
    generateRSAFromPBKDF2,
    exportCryptoKey,
    encryptWithPublicKey,
    importPublicKeyFromPem,
} from "../../../../Admin/js/Utilities/rsaGenerator.js";
import Icon from '../../../../Admin/js/Components/Common/Icon.vue'

const props = defineProps({
    showChatPanel: {
        type: Boolean,
        default: true,
    },
    showSignUp: {
        type: Boolean,
        default: true,
    },
    showResetPwd: {
        type: Boolean,
        default: false,
    },
    pwdResetSuccessful: {
        type: Boolean,
        default: false,
    },
    agent: {
        type: Object,
        default: AGENT,
    },
    invitationCode: {
        type: String,
        default: null,
    },
});

const emitter = defineEmits(["sign-in", "sign-up", "update:show-reset-pwd"]);

const { showSignUp, pwdResetSuccessful, invitationCode } = toRefs(props);

const showResetPwdRef = computed({
    get() {
        return props.showResetPwd;
    },
    set(value) {
        emitter("update:show-reset-pwd", value);
    },
});

const currentTab=ref('signin')
const refInputEmail = ref(null);
const refInputPwd = ref(null);

const loginError = ref("");
const loginErrorText = ref("");
const loginForm = ref({
    email: "",
    password: "",
    public_key: null,
    user_data_key: null,
});
const loginErrorMessages = reactive({
    email: false,
    password: false,
});

const screenSize = ref(0);
const showOTPInput = ref(false);
const showPassword = ref(false);
const showOtpModal = ref(false);
const isLoginDone = ref(false);
const isRequestInProgress = ref(false);
const showForgotPwd = ref(false);
const showTooltip = ref(false);
const isValidPassword = ref(false);
const showLoader = ref(false);
const showChatLoader = ref(false);
const expandDetails = ref(false);
const flagDrawer = ref(false);
const debouncedDrawer = ref(false);

const isSignUpOpen=computed(()=>currentTab.value=='signup')
const isSignInOpen=computed(()=>currentTab.value==='signin')
const emailValidateIcon = computed(() => {
    if (loginForm.value.email === "") return "mdi-email-outline";
    else if (verifyEmail()) return "mdi-checkbox-marked-circle-outline";
    else return "mdi-close-circle-outline";
});

const isEmailValid = computed(() =>
    /^[\w+]+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/.test(loginForm.value.email),
);
const isPasswordValid = computed(
    () =>
        loginForm.value.password.length >= 8 &&
        (/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(loginForm.value.password) ||
            /[0-9]/.test(loginForm.value.password)),
);
const isEmptyPasswordAndNotFocused = computed(
    () => !showTooltip.value && loginForm.value.password.length == 0,
);
const isFormDataValid = computed(
    () => isEmailValid.value && isPasswordValid.value,
);

 const isPasswordLengthValid=computed(()=> {
      return loginForm.value.password?.length >= 8;
    })

  const  containsNumberOrSymbol =computed(()=> {
      const regex = /[\d!@#$%^&*(),.?":{}|<>]/;
      return regex.test(loginForm.value.password);
    })

watch(
    () => isSignUpOpen,
    (obj) => {
        if (obj.value === null) {
            chatIsLoaded(true);
            flagDrawer.value = false;
        } else {
            chatIsLoaded(false);
        }
    },
);


watch(screenSize, setAgentDetailsStyleByViewport);

onMounted(() => {
    screenSize.value = innerWidth;
    setAgentDetailsStyleByViewport(screenSize.value);
    if(props.invitationCode){
        toggleTab()
    }
    window.onresize = () => {
        screenSize.value = innerWidth;
    };
});

function resetErrors() {
    loginErrorMessages.value = {
        email: false,
        password: false,
    };
}

function verifyEmail() {
    if (isEmailValid.value) return true;
    loginErrorMessages.email = true;
    return false;
}

function verifyPassword() {
    isValidPassword.value = showTooltip.value = false;

    if (isPasswordValid.value) return (isValidPassword.value = true);

    showTooltip.value = true;

    return false;
}

function isInputInvalid() {
    resetErrors();
    return !isEmailValid.value || !verifyPassword();
}

async function onSubmit() {
    return isSignUpOpen.value ? signUp() : signIn();
}

async function signIn() {
    if (isInputInvalid()) return;

    try {
        showLoader.value = true;
        requestStarted();
        loginError.value = false;

        // debugger;
        //TODO: Step 1: Generate Salt using email
        const password = loginForm.value.password;
        const salt = new TextEncoder().encode(loginForm.value.email);

        //TODO: Step 2: Generate Private Key using salt & password & derive public key as well using RSA
        const keyPair = await generateRSAFromPBKDF2(password, salt);
        const publicKey = await exportCryptoKey(keyPair.publicKey);
        const privateKey = await exportCryptoKey(keyPair.privateKey, true);

        const user_public_key = publicKey.pem;
        const user_private_key = privateKey.pem;

        //TODO: Extract actual public & private keys from pem format
        const originalPublicKey = await importPublicKeyFromPem(user_public_key);
        // const originalPrivateKey = await importPrivateKeyFromPem(user_private_key);

        //TODO: Step 3: Generate a organization data key
        const organization_date_key = forge.random.getBytesSync(32);

        if (!keyPair || !keyPair.publicKey) {
            console.warn("Public key not available. Generate keys first.");
            return;
        }

        //TODO: Encrypt organization data key using RSA
        const encrypted_organization_data_key = await encryptWithPublicKey(
            originalPublicKey,
            organization_date_key,
        );

        //TODO: Step 4: Extract user data key
        let user_data_key = btoa(
            String.fromCharCode(
                ...new Uint8Array(encrypted_organization_data_key),
            ),
        );
        console.log("User Data Key:", user_data_key);

        if (!keyPair || !keyPair.privateKey || !user_data_key) {
            console.warn("Private key or encrypted message not available.");
            return;
        }

        // const encryptedArrayBuffer = Uint8Array.from(atob(user_data_key), c => c.charCodeAt(0)).buffer;
        //
        // const decryptedData = await decryptWithPrivateKey(originalPrivateKey, encryptedArrayBuffer);
        // let decryptedMessage = decryptedData;
        // console.log("Decrypted message:", decryptedMessage);

        const expires = new Date();
        expires.setTime(expires.getTime() + 7 * 24 * 60 * 60 * 1000); // 7 from execution of this code

        const cookieValue =
            encodeURIComponent(user_private_key) +
            "; expires=" +
            expires.toUTCString() +
            "; path=/; Secure; SameSite=Strict;";

        document.cookie = "user_private_key=" + cookieValue;

        loginForm.value.public_key = user_public_key;
        loginForm.value.user_data_key = user_data_key;

        const { data } = await axios.post("/login", loginForm.value);
        useLogin().setUserId(data.data.user_id);
        //localStorage.setItem('kaamfu_user_id', data.user_id)
        //localStorage.setItem('kaamfu_user_token', data.user_id)

        isLoginDone.value = true;
        requestProcessed();

        if (data.data.is_verified) {
            goToURL(data.data.redirection_url);
        } else {
            useLogin().setToken(data.data.token);
            showOtpModal.value = true;
        }
    } catch (err) {
        updateErrorResponseMessage(err.response);
        requestProcessed();
        loginError.value = true;
    }
}

async function signUp() {
    if (isInputInvalid()) return;

    loginForm.value.invitation_code = invitationCode;

    try {
        localStorage.setItem("email", loginForm.value.email);
        localStorage.setItem("password", loginForm.value.password);

        requestStarted();
        loginError.value = false;
        const { data } = await axios.post("/register", loginForm.value);
        useLogin().setUserId(data.data?.user_id);
        useLogin().setToken(data.data?.token);
        showOTPInput.value = true;
        isLoginDone.value = true;
        requestProcessed();
        //$('.prospus-otp-wrap').fadeIn();
        showOtpModal.value = true;
    } catch (err) {
        //console.log("error while registering", )
        updateErrorResponseMessage(err.response);
        requestProcessed();
        loginError.value = true;
        showLoader.value = false;
    }
}

function updateErrorResponseMessage(response) {
    if (
        response?.status === HttpStatusCodes.UNAUTHORIZED ||
        response?.status === HttpStatusCodes.BAD_REQUEST
    ) {
        loginErrorText.value = response?.data?.message;
    }

    if (response?.data?.already_registered) emitter("signIn");
}

function requestStarted() {
    loginErrorText.value = "";
    isRequestInProgress.value = true;
    showLoader.value = true;
}

function requestProcessed() {
    isRequestInProgress.value = false;
    showLoader.value = false;
}

function goToURL(url = null) {
    if (url) {
        window.location.href = url;
        return;
    }

    window.location.reload();
}

function toggleView() {
    isLoginDone.value = false;
    loginForm.value.email = "";
    loginForm.value.password = "";
    loginError.value = false;
    loginErrorText.value = "";
    showOTPInput.value = false;
    setFocusOnEmail();
}

function setFocusOnEmail() {
    refInputEmail.value.focus();
    loginErrorMessages.email = true;
}

function toggleVisiblity() {
    showPassword.value = !showPassword.value;
    showTooltip.value = true;
    let timeout = setTimeout(() => {
        refInputPwd.value.focus();
        refInputPwd.value.setSelectionRange(
            loginForm.value.password.length,
            loginForm.value.password.length,
        );
        clearTimeout(timeout);
    }, 500);
}



function enableUserToUpdateAndSignup() {
    showOtpModal.value = false;
    showOTPInput.value = false;
    isLoginDone.value = false;
    loginError.value = false;
    loginErrorText.value = "";
}

function registrationCanceled() {
    showOtpModal.value = false;
    toggleView();
    loginForm.value.email = loginForm.value.password = "";
}

function chatIsLoaded(val) {
    if (val !== showChatLoader.value) {
        if (val) {
            let timeout = setTimeout(() => {
                emitter("established-connection", true);
                emitter("on-chat-load", true);
                showChatLoader.value = true;
                clearTimeout(timeout);
            }, 2000);
        } else {
            // showChatLoader.value = false
            // emitter('on-chat-load', false)
        }
    }
}



function setAgentDetailsStyleByViewport  (viewport)  {
    const agentDetailsSection = document.querySelector(
        ".prospus-expanded-details",
    );
    if (viewport <= 1024) {
        if (agentDetailsSection) {
            agentDetailsSection.style.webkitAnimation = "none";
            agentDetailsSection.style.animation = "none";

            if (viewport <= 449) {
                agentDetailsSection.style.width = `${viewport - 24}px`;
                agentDetailsSection.style.right = `calc(-${viewport}px + 24px)`;
            } else {
                agentDetailsSection.style.width = "436px";
                agentDetailsSection.style.right = `-436px`;
            }
        }
    } else {
        if (agentDetailsSection) {
            agentDetailsSection.style.webkitAnimation = "";
            agentDetailsSection.style.animation = "";
            agentDetailsSection.style.right = "";
            agentDetailsSection.style.width = "";
        }
    }
};

function changeTab(tabName) {
    loginForm.value.email =  "";
    loginForm.value.password =  "";
    loginForm.value.public_key =  null;
    loginForm.value.user_data_key =  null;
    loginError.value = false;
    loginErrorText.value = "";
    currentTab.value=tabName
}
function toggleTab(){
    if(isSignUpOpen.value){
        currentTab.value='signin'
    }else{
        currentTab.value='signup'
    }
}


</script>
<template>
    <div class="tabs">
        <button class="tab-link  position-relative" :class="{active:isSignInOpen}" @click="changeTab('signin')">Sign In</button>
        <button class="tab-link position-relative" :class="{active:isSignUpOpen}" @click="changeTab('signup')">Sign Up</button>
    </div>
    <div class="auth__container">
        <div  class="tab-content active" style="display: block">
            <form @submit.prevent="onSubmit">
                <v-img
                    :src="logoSmall"
                    alt="Kamfu Logo"
                    class="auth-common-logo"
                ></v-img>
                <div>
                    <h2 class='mb-5 text-center font-weight-500 font-poppions' >
                        {{isSignInOpen? 'Sign in to your':'Sign up for your free' }}
                        <br />
                        <img :src="logo" alt="Kaamfu Logo" class="logo" height="20px"></img>
                        account
                    </h2>
                    <div class="input-group envelope">
                <img v-if="emailValidateIcon=='mdi-email-outline'"
                    :src="envelope"
                    alt="Email Icon"
                    class="input-icon"
                />
                 <Icon
                        v-else
                    class=" input-icon mx-2"
                    size="20"
                    :class="`${showSignUp ? 'sign-up' : 'sign-in'}`"
                    >
                        {{ emailValidateIcon }}
                    </Icon>
                <input ref="refInputEmail" v-model="loginForm.email" type="email" placeholder="Email" required  autocomplete="false" />
                </div>

                <div class="input-group lock prospus-input-wrapper">
                                <Icon
                                    v-if="isPasswordValid"
                                    :class="`${isSignUpOpen ? 'sign-up' : 'sign-in'}`"
                                    :style="{
                                        fontSize: '21px',
                                        margin: '0 8.5px'
                                    }"
                                >
                                    mdi-checkbox-marked-circle-outline
                                </Icon>
                                <img
                                    v-else-if="isEmptyPasswordAndNotFocused"
                                     :src="lock"
                                        alt="Lock Icon"
                                        class="input-icon"
                                />
                                <Icon
                                    v-else
                                    :class="`${isSignUpOpen ? 'sign-up' : 'sign-in'}`"

                                    :style="{
                                        fontSize: '21px',
                                        margin: '0 8.5px'

                                    }"
                                    >mdi-information-outline</Icon>


                                <input
                                    ref="refInputPwd"
                                    v-model="loginForm.password"
                                    :type="`${showPassword ? 'text' : 'password'}`"
                                    placeholder="Password" r
                                    equired  autocomplete="false"
                                    @focusin="showTooltip = true"
                                    @focusout="showTooltip = false"
                                />
                                <Icon class="toggle-password" :size="20" @click="toggleVisiblity">{{ showPassword?'mdi-eye':'mdi-eye-off' }}</Icon>
                                <PasswordCriteriaTooltip
                                    v-if="showTooltip"
                                    :password="loginForm.password"
                                    @validity="updatePwdValidity"
                                />
                            </div>

                <div
                    class="input-group"
                    v-if="isSignUpOpen && invitationCode"
                >
                    <span class="material-symbols-rounded mx-2">
                                    keyboard
                            </span>
                    <div
                        class="input-wrapper prospus-has-validation-check"
                        :class="loginErrorMessages.code ? 'has-error' : ''"
                    >
                            <input
                                :value="invitationCode"
                                tabindex="3"
                                type="text"
                                class="prospus-invitation"
                                name="invitationCode"
                                title="invitationCode"
                                autofocus
                                readonly
                                autocomplete="false"
                                :style="{
                                    width: 'auto !important'
                                }"
                                @focusin="loginErrorMessages.code = true"
                                @focusout="loginErrorMessages.code = false"
                            />
                    </div>
                </div>

                <div v-if="isSignInOpen" class="remember-me">
                    <input type="checkbox" id="remember" />
                    <label for="remember">Keep me signed in</label>
                </div>
                <div v-else class="remember-me">
                    <input type="checkbox" id="remember" required />
                    <label for="remember">I agree to the <a href="https://kaamfu.io/terms-conditions/" target="_blank">Terms & Conditions</a></label>
                </div>

                </div>
                <div class="buttons">
                    <a  v-if="isSignInOpen" class="prospus-link prospus-mr-8" @click="showForgotPwd=true" >Forgot Password?</a>
                    <button type="submit" :class="{
                                'prospus-signup ': showSignUp,
                                'prospus-signin ': !showSignUp,
                                'btn-dark': !(
                                    showLoader ||
                                    isLoginDone ||
                                    !isFormDataValid
                                ),
                            }"
                            :disabled="isRequestInProgress || isLoginDone || !isFormDataValid" class="btn">{{isSignInOpen? 'Sign In':'Sign Up'}}</button>
                </div>
                <p class="signup-instead"><a  class=" text-decoration-underline" @click="toggleTab">{{ isSignInOpen?'Sign up':'Sign in' }}</a> instead</p>
            </form>
             <span
                    v-if="loginError"
                    class="d-flex ga-24 justify-center mt-5"
                >
                    <span class="material-icons-round prospus-search mr-1">
                        warning
                        </span>
                    <span class="error-message">
                        {{
                            loginErrorText ? loginErrorText : "The provided credentials do not match our records"
                        }}
                    </span>
                </span>
        </div>
        <Transition v-if="pwdResetSuccessful" duration="550" name="nested">
            <ResetPwdSuccess
                @close="
                    $emit('close-pwd-success');
                    setFocusOnEmail;
                "
            ></ResetPwdSuccess>
        </Transition>
        <Transition v-if="showForgotPwd" duration="550" name="nested">
            <ForgotPassword v-if="showForgotPwd" @close="showForgotPwd = false"></ForgotPassword>
        </Transition>
        <Transition v-if="showResetPwdRef" duration="550" name="nested">
            <ResetPassword
                @on-load="$emit('sign-in')"
                @close="showResetPwdRef = false"
            >
            </ResetPassword>
        </Transition>
        <Transition v-if="showOtpModal" duration="550" name="nested">
            <OtpModal
                :show-sign-up="isSignUpOpen"
                @cancel="registrationCanceled"
                @reset="enableUserToUpdateAndSignup"
            >
            </OtpModal>
        </Transition>
    </div>
</template>
<style lang="scss">
@import '../../../../../js/Auth/sass/_colors.sass';
.auth__container {
    .font-poppions{
        font-family: "Poppins", serif !important;
    }
    .tab-content h2{
        font-size: 21px;
    }
    background: rgba(48, 36, 92, 0.5);
    padding: 30px;
    border-radius: 10px;
    width: 350px;
    border: none;
    box-shadow: 0 0 0 0.3px $light-grey;
    .auth-common-logo {
        height: 50px;
        display: block;
        margin: 0 auto 20px auto;
    }

    .input-group {
        position: relative;
        display: flex;
        align-items: center;
        background: transparent;
        padding: 0px;
        border-radius: 25px;
        border: 1px solid $light-grey;
        width: 100%;
        margin-bottom: 15px;

    }

    .envelope img {
        width: 16px;
        margin: 0 11px;
    }
    .lock img {
        width: 16px;
        margin: 0 11px;
    }

    .input-group input {
    flex: 1;
    background: transparent;
    border: none;
    outline: none;
    padding: 10px 0;
    color: white;
    font-size: 14px;
    &::placeholder {
    color: $light-grey;
    }
    }

    input::-ms-reveal,
      input::-ms-clear {
        display: none;
      }
    .signup-instead {
    margin-top: 15px;
    font-style: italic;
    color: $light-grey;
    font-size: 12px;
    text-align: right;
    padding: 0 23px;
    cursor: pointer;
    color: $light-grey;
    font-weight: 500;
    }

    .remember-me {
    display: flex;
    align-items: center;
    gap: 11px;
    padding: 0 13px;
    margin-bottom: 45px;
    label{
        a {
            color: $light-grey;
        }
    }

}
input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
}
input[data-autocompleted] {
    background-color: transparent !important;
}.toggle-password {
    margin: 0 10px;
    cursor: pointer;
}
    .buttons {
        display: flex;
        justify-content: right;
        align-items: center;
        a {
            color: $light-grey;
            text-decoration: none;
            font-size: 14px;
            font-weight: 500;
        }
        .btn {
            background: #493e8a;
            color: $light-grey;
            padding: 8px 20px;
            border: none;
            font-size: 13.3px;
            font-weight: 500;
            border-radius: 20px;
            margin-left: 15px;
            cursor:pointer;

            &:disabled {
                background: #3e337b;
                cursor: auto;
            }
            &:not(:disabled):hover {
                background: #50429f;
                color: #ffffff;
            }
        }
}
.remember-me input[type="checkbox"] {
    appearance: none;
    width: 15px;
    height: 15px;
    background-color: $light-grey;
    border-radius: 4px;
    border: 2px solid transparent;
    display: inline-block;
    cursor: pointer;
    transition: 0.2s ease-in-out;
}

.remember-me input[type="checkbox"]:checked {
    background-color: $light-grey; /* Background when checked */
    border: 2px solid #ddd;
}

.remember-me input[type="checkbox"]::before {
    content: "\2713"; /* Checkmark */
    font-size: 16px;
    color: #000;
    display: block;
    text-align: center;
    line-height: 12px;
    opacity: 0;
    transition: 0.2s ease-in-out;
}

.remember-me input[type="checkbox"]:checked::before {
    opacity: 1;
}

.remember-me label {
    font-size: 14px;
}
}
.tabs {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    padding: 20px 34px;
}

.tab-link {
    background: none;
    border: none;
    color: $light-grey;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    padding-bottom: 5px;
    font-family: "Poppins", serif !important;
}

.tab-link.active::after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: -6px;
    width: 100%;
    height: 3px;
    background-color: $light-grey;
    border-radius: 10px;
    transform: translateX(-50%);
}
</style>

<style lang=scss>

@import '../../../../../js/Auth/sass/_colors.sass';

.prospus-input-wrapper{
    .prospus-tooltip-wrapper{
        position: absolute;
        left: 0;
        padding: 5px 10px;
        background: #253663;
        border: 1px solid $white;
        border-radius: 4px;
        height: auto;
        top: 50px;
        min-width: calc(100% - 100px);
        z-index: 2;
        ul{
          padding: 0;
          margin: 0;
          list-style: none;
          font-size: 12px;
          li {
            display: flex;
            align-items: center;
            span{
              font-size: 14px;
              margin-right: 5px;
            }
          }
        }
        &__arrow{
            border: 1px solid $white;
            border-bottom: none;
            border-right: none;
            height: 10px;
            width: 10px;
            position: absolute;
            top: 3px;
            left: 10px;
            transform: translatey(-9px) rotate(45deg);
            background-color: $prospus-blue;
        }
    }
}
</style>
